import { http } from './config'
import { encriptar } from '../config/cripto';

export default {
    listar: () => {
        return http.get(`/cidades/listar`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    incluir: (cidade) => {
        const params = {
            cidade: cidade
        }
        return http.post('/cidades/incluir', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    alterar: (cidade) => {
        const params = {
            cidade: cidade
        }
        return http.put('/cidades/alterar', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    excluir: (id) => {
        return http.delete(`/cidades/excluir/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    pesquisar: (id) => {
        return http.get(`/cidades/pesquisar/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    pesquisarficha: (id) => {
        return http.get(`/cidades/pesquisarficha/${encriptar(id)}`)
    },
}