import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"centro"},[_c(VCard,{staticClass:"mx-auto elevation-10 mb-2",attrs:{"outlined":"","id":"cartao"}},[_c(VCardTitle,{staticClass:"mb-10"},[_c('svg-icon',{attrs:{"height":"50px","width":"50px","type":"mdi","path":_vm.icone}}),(this.$route.fullPath == '/cidades/new')?_c('h3',[_vm._v("Cadastro de Cidades (Inclusão)")]):_c('h3',[_vm._v("Cadastro de Cidades (Alteração)")])],1),_c('form',{attrs:{"id":"formulario"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"id":"id","name":"id","dense":"","disabled":"","label":"ID"},model:{value:(_vm.cidade.id),callback:function ($$v) {_vm.$set(_vm.cidade, "id", $$v)},expression:"cidade.id"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c(VTextField,{attrs:{"id":"nome","name":"nome","dense":"","label":"Nome"},model:{value:(_vm.cidade.nome),callback:function ($$v) {_vm.$set(_vm.cidade, "nome", $$v)},expression:"cidade.nome"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VSelect,{attrs:{"id":"uf","name":"uf","dense":"","items":['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'],"label":"UF"},model:{value:(_vm.cidade.uf),callback:function ($$v) {_vm.$set(_vm.cidade, "uf", $$v)},expression:"cidade.uf"}})],1)],1)],1),_c(VBtn,{staticClass:"botoes",attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"botoes",attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }