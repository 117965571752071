<template>
    <div class="centro">
      <v-card
        outlined
        id="cartao"
        class="mx-auto elevation-10 mb-2"
      >
        <v-card-title class="mb-10">
          <svg-icon height="50px" width="50px" type="mdi" :path="icone"></svg-icon>
          <h3 v-if="this.$route.fullPath == '/cidades/new'">Cadastro de Cidades (Inclusão)</h3>
          <h3 v-else>Cadastro de Cidades (Alteração)</h3>
        </v-card-title>
        <form id="formulario">
          <v-row>
            <v-col cols="2">
              <v-text-field 
                id="id" 
                name="id"
                dense
                disabled
                v-model="cidade.id"
                label="ID"
              >
              </v-text-field>
            </v-col>
        </v-row>
          <v-row>
            <v-col cols="10">
              <v-text-field 
                id="nome" 
                name="nome"
                dense
                v-model="cidade.nome"
                label="Nome"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select
                id="uf" 
                name="uf"
                dense
                :items="['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']"
                v-model="cidade.uf"
                label="UF"
              ></v-select>
            </v-col>
          </v-row>
        </form>
        <v-btn class="botoes" color="blue darken-1" text @click="cancel"> Cancelar </v-btn>
        <v-btn class="botoes" color="blue darken-1" text @click="save"> Salvar </v-btn>
      </v-card>
    </div>
  </template>
  
  <script>
  import svcCidades from "../../services/svcCidades";
  import SvgIcon from '@jamescoyle/vue-icon';
  import { decriptar } from "../../config/cripto";
  import { mdiCity } from '@mdi/js';
  
  export default {
    components: {
          SvgIcon
      },
    data() {
      return {
        icone: mdiCity,
        cidade: {
          id: "",
          nome: "",
          uf: "",
        },
      };
    },
  
    methods: {
      pesquisa(id) {
        svcCidades
          .pesquisar(id)
          .then((resp) => {
            this.cidade = resp.data;
          })
          .catch((error) => {
            alert(error);
          });
      },
  
      cancel() {
        this.reposiciona();
      },
  
      save() {
        if (this.$route.fullPath == "/cidades/new") {
          // Novo Municipio
          svcCidades
            .incluir(this.cidade)
            .then((resp) => {
              if (resp.status == 200) {
                this.cidade = resp.data;
                this.reposiciona();
              } else {
                alert("Erro inserindo nova cidade.");
              }
            })
            .catch((error) => {
              alert(error);
            });
        } else {
            svcCidades
              .alterar(this.cidade)
              .then((resp) => {
                if (resp.status != 200) {
                  alert("Erro alterando dados da cidade.");
                }
              })
              .then(() => {
                this.reposiciona();
              })
              .catch((error) => {
                alert(error);
              });
        }
      },
  
      reposiciona() {
        this.$router.push("/cidades");
      },
    },
  
    mounted() {
      if (this.$route.fullPath != "/cidades/new") {
        this.pesquisa(decriptar(this.$route.params.id))
      }
    },
  };
  </script>
  
  <style scoped>
  #formulario {
    max-width: 800px;
    margin: 0 auto;
  }
  #cartao {
    max-width: 800px;
    padding: 0px 50px 50px 50px;
    margin-top: 0px;
    margin-bottom: 120px;
    /* background-color: #dbdbdb; */
  }
  .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1px;
  }
  label {
    font-weight: bold;
    margin-bottom: 1px;
    color: #222;
    text-align: left;
  }
  .botoes {
    margin-top: 5px;
  }
</style>
  